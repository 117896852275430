<template>
  <div class="d-flex-column align-center main rel-wrap">
    <el-form label-position="top" class="full-width input-underline">
      <div class="title align-text-center">
        <h3 class="m-0">Add new participant</h3>
      </div>
      <el-card class="mt-2 mb-2 info-card">
        <h4>Have you registered before?</h4>
        <p>
          Please use the same details as your previous account so that we can match it and bring
          your details over to MySideline.
        </p>
      </el-card>
      <el-card>
        <div class="mw-400">
          <div v-if="registerScreen">
            <LogoTitleSubtitle
              v-if="current.entity.type === 'club'"
              :title="current.entity.name"
              :subtitle="current.entity.orgtree.association.name"
              :img="endpointParams.logo(current.entity._id)"
            />
            <LogoTitleSubtitle
              v-if="current.entity.type === 'program'"
              :title="object.name"
              :subtitle="object.type"
            />
          </div>
          <SuperInput
            id="firstName"
            :field="form.firstName"
            v-model="form.firstName.value"
            :errors.sync="form.firstName.errors"
          />
          <SuperInput
            :field="form.middleName"
            v-model="form.middleName.value"
            :errors.sync="form.middleName.errors"
          />
          <SuperInput
            id="lastName"
            :field="form.lastName"
            v-model="form.lastName.value"
            :errors.sync="form.lastName.errors"
          />
          <SuperInput
            id="dob"
            :field="form.dob"
            v-model="form.dob.value"
            :errors.sync="form.dob.errors"
          />
          <SuperInput
            id="gender"
            class="mb-0"
            :field="form.gender"
            v-model="form.gender.value"
            :errors.sync="form.gender.errors"
          />
        </div>
      </el-card>

      <div class="d-flex-column main mw-400">
        <div>
          <el-button id="addParticipant" type="primary" class="full-width" @click="submitForm">
            Add Participant
          </el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SuperInput from "@/components/form/fields/SuperInput.vue";
import LogoTitleSubtitle from "@/components/LogoTitleSubtitle.vue";
import { errorOrPass, flatten, makeForm } from "@/utils/forms";
import { genderOptions } from "@/utils/constants";

export default {
  name: "MemberForm",
  components: {
    SuperInput,
    LogoTitleSubtitle,
  },
  props: {
    profileScreen: Boolean,
    lc: Boolean,
  },
  computed: {
    ...mapGetters("registration", ["current"]),
    registerScreen() {
      return !this.profileScreen && !this.lc;
    },
  },
  methods: {
    ...mapActions("views", ["triggerEvent"]),
    ...mapMutations("registration", ["SEARCH_INPUT"]),
    ...mapMutations("root", ["LOADING"]),
    submitForm() {
      // Validate form
      if (!errorOrPass(this.form, [], this)) return;
      const { firstName, middleName, lastName, dob, gender } = flatten(this.form);
      // Set search input data (vuex)
      this.SEARCH_INPUT({
        firstName,
        middleName,
        lastName,
        dob,
        gender,
      });
      // Pass profileScreen prop - helps with going back to profile or rego flows
      // eslint-disable-next-line max-len
      this.$confirm(
        "Please double check your details are correct.\n\n If you have registered before please check that they match your previous account.",
        "Are your details correct?",
        {
          confirmButtonText: "Continue",
          cancelButtonText: "Edit",
        }
      )
        .then(() => {
          this.LOADING(true);
          if (this.profileScreen) {
            const rootUrl = this.$store.state.privateLink.rootUrl;
            this.$router.push({ name: "linkprofile", params: { fromPath: rootUrl } }).catch((e) => {
              console.error("error nav linkprofile", { e });
            });
          } else if (this.lc) {
            this.$router.push({ name: "lclinkprofile" }).catch(() => {
              console.error("error nav lclinkprofile");
            });
          } else {
            this.$router.push({ name: "linkmember" }).catch(() => {
              console.error("error nav linkmember");
            });
          }
        })
        .catch(() => {});
    },
  },
  data() {
    return {
      form: makeForm({
        firstName: {
          label: "First Name",
        },
        middleName: {
          label: "Middle Name (Optional)",
          required: false,
        },
        lastName: {
          label: "Last Name",
        },
        dob: {
          component: "DateInput",
          label: "Birthday",
        },
        gender: {
          component: "SelectSP",
          label: "Gender",
          options: genderOptions,
        },
      }),
    };
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-family: $fontMedium;
}

.info-card {
  text-align: center;

  h4 {
    margin-top: 0;
    font-family: $fontMedium;
  }
}
</style>
